import React from 'react';
import AuthWrapper from './AuthWrapper';
import { Coffee } from 'lucide-react';
import './App.css';

function App() {
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <header className="py-4 bg-white shadow-sm">
        <h1 
          onClick={() => window.location.reload()} 
          className="text-2xl font-light text-center text-gray-800 cursor-pointer hover:text-gray-600 transition-colors"
        >
          Minikal
        </h1>
        <p className="text-sm font-light text-center text-gray-500 mt-1">Just a simple calendar</p>
      </header>
      <main className="flex-grow flex items-start justify-center p-2 sm:p-4 mt-2 sm:mt-4">
        <AuthWrapper />
      </main>
      <footer className="py-4 bg-white border-t">
        <div className="container mx-auto px-4 flex items-center justify-center gap-2">
          <p className="text-sm font-light text-gray-600">
            Minikal is free. If you find it useful{' '}
            <a 
              href="https://buymeacoffee.com/ismaelponce" 
              target="_blank" 
              rel="noopener noreferrer"
              className="text-gray-800 hover:underline inline-flex items-center gap-1"
            >
              buy me a coffee
              <Coffee className="h-4 w-4" />
            </a>
          </p>
        </div>
      </footer>
    </div>
  );
}

export default App;